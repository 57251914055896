import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './Login.css';

import AppContext from '../bin/config/AppContext';

import logo from '../assets/Group 3@2x.png';

export default function LoginComponent() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  return (
    <AppContext.Consumer>
      {context =>
        localStorage.length === 0 && !context.user.isLoggedIn ? (
          <div id={'login'} className={'container'}>
            <div className={'loginLogo'}>
              <NavLink replace to='/'>
                <img alt='pinit-logo' src={logo} />
              </NavLink>
            </div>
            <form
              className={'loginForm'}
              onSubmit={e => context.handleLoggedIn(e, { username, password })}
            >
              <label className={'form-label'}>Username:</label>
              <input
                type={'text'}
                className={'form-control'}
                value={username}
                onChange={e => setUsername(e.target.value)}
                placeholder={'Input username'}
                autoFocus
              />
              <label className={'form-label'}>Password:</label>
              <input
                type={'password'}
                className={'form-control'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder={'Input password'}
              />
              <button type={'submit'} className={'btn btn-md form-control'}>
                Login
              </button>
            </form>
          </div>
        ) : (
          <div
            id={'login'}
            className={'container'}
            style={{ textAlign: 'center' }}
          >
            <div className={'loginLogo'}>
              <img alt='pinit-logo' src={logo} />
            </div>
            Loading ...
          </div>
        )
      }
    </AppContext.Consumer>
  );
}
