import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export function UnAuthModal(props) {
	return (
		<Modal show={props.show} onHide={props.onHide} centered>
			<Modal.Header>
				<Modal.Title>Unauthorized!</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{props.message}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={props.onHide}>
					Okay
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export function ConfirmLogout(props) {
	return (
		<Modal show={props.show} onHide={props.onHide} centered>
			<Modal.Header>
				<Modal.Title>Logout!</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Do you want to logout?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant={'success'} onClick={props.logout}>
					Yes
				</Button>
				<Button variant={'secondary'} onClick={props.onHide}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export function ConfirmModal(props) {
	const pathname = window.location.pathname;
	return (
		<Modal show={props.show} onHide={props.onHide} centered>
			<Modal.Header>
				<Modal.Title>Confirm Delete!</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<p>
					Are you sure you want to delete this{' '}
					{/events/.test(pathname)
						? 'Event'
						: /issues/.test(pathname)
						? 'Issue'
						: /assets/.test(pathname) ||
						  /peoples/.test(pathname) ||
						  /places/.test(pathname)
						? 'Asset'
						: /users/.test(pathname)
						? 'User'
						: ''}
					?
				</p>
			</Modal.Body>

			<Modal.Footer>
				<Button
					variant='danger'
					onClick={() => props.handleDelete(props.rowId)}
				>
					Delete
				</Button>
				<Button variant='secondary' onClick={props.onHide}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export function InfoModal(props) {
	return (
		<Modal show={props.show} onHide={props.onHide} centered>
			<Modal.Header>
				<Modal.Title>Confirm status change!</Modal.Title>
			</Modal.Header>
			<Modal.Body>{props.message}</Modal.Body>
			<Modal.Footer>
				{props.handleToggle ? (
					<>
						<Button variant={'success'} onClick={() => props.handleToggle()}>
							Okay
						</Button>
						<Button variant={'secondary'} onClick={props.onHide}>
							Cancel
						</Button>
					</>
				) : (
					<Button variant={'secondary'} onClick={props.onHide}>
						Okay
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
}

export function AdminModal(props) {
	const [name, setName] = React.useState('');
	const [email_id, setEmail] = React.useState('');
	const [contact_number, setContact] = React.useState('');
	return (
		<Modal show={props.show} onHide={props.onHide} centered>
			<Modal.Header>
				<Modal.Title>Add New Admin!</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className={'form-group'}>
						<label>Name:</label>
						<input
							className={'form-control'}
							type={'text'}
							name={'name'}
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</div>
					<div className={'form-group'}>
						<label>Email:</label>
						<input
							className={'form-control'}
							type={'text'}
							name={'email'}
							value={email_id}
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
					<div className={'form-group'}>
						<label>Contact Details</label>
						<input
							className={'form-control'}
							type={'text'}
							name={'contact'}
							value={contact_number}
							onChange={e => setContact(e.target.value)}
						/>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={props.onHide}>
					Cancel
				</Button>
				<Button
					variant='success'
					onClick={() => props.handleAdd({ name, email_id, contact_number })}
				>
					Add!
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
