import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './bin/config/routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import IndexRouter from './Pages/Index';
import NoPageFound from './Pages/PageNotFound';

export default function App() {
	return (
		<Router>
			<div className='App'>
				<Switch>
					<Route exact path={routes} component={IndexRouter} />
					<Route component={NoPageFound} />
				</Switch>
			</div>
		</Router>
	);
}
