import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import { google_api_key } from '../../bin/api/Api';
import InfoWindowEx from '../Utilities/InfoWindowEx';
import Ratings from '../Utilities/Ratings';

import './Map.css';

import ic_maps_active from '../../assets/map-active.png';
import ic_location from '../../assets/map@2x.png';
import ic_noimage from '../../assets/ic_no-image.png';
import ic_event_location from '../../assets/orange-marker@2x.png';
import ic_issue_location from '../../assets/red-marker@2x.png';
import ic_people_location from '../../assets/blue-marker@2x.png';
import ic_place_location from '../../assets/green-marker@2x.png';

const initialBounds = {
	upper_right: `15.669948,74.154871`,
	bottom_left: `15.47548,73.689344`
};

export class MapContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoWindow: false,
			activeMarker: {},
			selectedPlace: {},
			filter: false,
			filtered: [],
			events: [],
			issues: [],
			peoples: [],
			places: []
		};
		this.handleFilters = this.handleFilters.bind(this);
		this.displayCards = this.displayCards.bind(this);
		this.displayMarkers = this.displayMarkers.bind(this);
		this.getMarkers = this.getMarkers.bind(this);
		this.onMapClicked = this.onMapClicked.bind(this);
		this.onMarkerClick = this.onMarkerClick.bind(this);
	}

	handleFilters = e => {
		const category = e.target.getAttribute('name');
		if (this.state.filtered === this.state[category])
			this.setState({
				filter: false,
				filtered: []
			});
		else
			this.setState({
				filter: true,
				filtered: this.state[category]
			});
	};
	displayCards = category => {
		const cardsStyles = {
			padding: '25px 20px',
			margin: '0 3px',
			textTransform: 'capitalize',
			backgroundColor: '#ffffff',
			color:
				this.state.filtered === this.state[category] ? '#69a338' : '#2f364a',
			cursor: 'pointer',
			userSelect: 'none'
		};
		const cardSpans = {
			float: 'right',
			marginRight: '30px',
			padding: '2px 8px',
			borderRadius: '10px',
			height: '20px',
			width: '30px',
			color: '#ffffff',
			fontSize: '12px',
			backgroundColor:
				this.state[category].length > 0 &&
				this.state[category][0].category_color
		};
		return (
			<div name={category} onClick={this.handleFilters} style={cardsStyles}>
				{category}
				<span style={cardSpans}>{this.state[category].length}</span>
			</div>
		);
	};
	getMarkers = (bounds = initialBounds) => {
		this.props.handleMarkers(bounds, (err, response) =>
			!err && response !== null
				? this.setState({ ...response, filter: false })
				: this.setState({ ...this.state }, console.warn(err))
		);
	};
	onMarkerClick = (props, marker, e) => {
		this.setState({
			selectedPlace: props.category,
			activeMarker: marker,
			showInfoWindow: true
		});
	};
	onMapClicked = props => {
		if (this.state.showInfoWindow) {
			this.setState({
				showInfoWindow: false,
				activeMarker: null,
				selectedPlace: {}
			});
		}
	};
	displayMarkers = () => {
		if (this.state.filter && this.state.filtered !== undefined) {
			return this.state.filtered.map((store, index) => {
				return (
					<Marker
						key={index}
						id={index}
						icon={
							store.section_name === 'Events'
								? ic_event_location
								: store.section_name === 'Issues'
								? ic_issue_location
								: store.section_name === 'People'
								? ic_people_location
								: store.section_name === 'Places'
								? ic_place_location
								: ic_location
						}
						position={store.position}
						onClick={this.onMarkerClick}
						category={store}
					/>
				);
			});
		} else {
			const categories = ['places', 'peoples', 'issues', 'events'];
			return categories.map(category => {
				return this.state[category].map((store, index) => {
					return (
						<Marker
							key={index}
							id={index}
							icon={
								store.section_name === 'Events'
									? ic_event_location
									: store.section_name === 'Issues'
									? ic_issue_location
									: store.section_name === 'People'
									? ic_people_location
									: store.section_name === 'Places'
									? ic_place_location
									: ic_location
							}
							position={store.position}
							onClick={this.onMarkerClick}
							category={store}
						/>
					);
				});
			});
		}
	};
	render = () => {
		const bounds = new this.props.google.maps.LatLngBounds(
			{ lat: 15.669948, lng: 74.154871 },
			{ lat: 15.47548, lng: 73.689344 }
		);
		const mapStyles = {
			width: '96%',
			height: '400px'
		};
		const cardsContainer = {
			position: 'relative',
			margin: '0 auto',
			marginTop: '430px',
			display: 'grid',
			gridTemplateColumns: '1fr 1fr 1fr 1fr',
			borderRadius: '15px',
			width: '80%'
		};
		return (
			<div id='map'>
				<h1 className={'dashboard-heading'}>
					<img
						className={'dashboard-icon'}
						alt={'Maps Icon'}
						src={ic_maps_active}
					/>
					Maps
				</h1>
				<Map
					google={this.props.google}
					zoom={14}
					style={mapStyles}
					initialBounds={bounds}
					initialCenter={{ lat: 15.51616, lng: 73.83807 }}
					onClick={this.onMapClicked}
				>
					{this.displayMarkers()}
					<InfoWindowEx
						marker={this.state.activeMarker}
						visible={this.state.showInfoWindow}
					>
						<div className={'info-grid'}>
							<div className={'info-details'}>
								{!/[eE]vents/.test(this.state.selectedPlace.section_name) && (
									<span
										className={'info-category'}
										style={{
											backgroundColor: this.state.selectedPlace.category_color
										}}
									>
										{this.state.selectedPlace.category_name}
									</span>
								)}
								<h3 className={'info-title'}>
									<a
										href={
											this.state.selectedPlace.section_name &&
											`${this.state.selectedPlace.section_name
												.toLowerCase()
												.replace('people', 'peoples')}/${
												this.state.selectedPlace.id
											}`
										}
									>
										{this.state.selectedPlace.title}
									</a>
								</h3>
								<span className={'info-location'}>
									<img alt={'ic_location'} src={ic_location} />
									{this.state.selectedPlace.location_name}
								</span>
								<span className={'info-ratings'}>
									<Ratings ratings={this.state.selectedPlace.rating} />
								</span>
							</div>
							<div className={'info-image'}>
								<img
									alt={'info'}
									src={
										this.state.selectedPlace.images &&
										this.state.selectedPlace.images[0]
											? this.state.selectedPlace.images[0]
											: ic_noimage
									}
								/>
							</div>
						</div>
					</InfoWindowEx>
				</Map>
				<div style={cardsContainer}>
					{this.displayCards('places')}
					{this.displayCards('peoples')}
					{this.displayCards('events')}
					{this.displayCards('issues')}
				</div>
			</div>
		);
	};
	componentDidMount = () => {
		this.getMarkers();
	};
}

export default GoogleApiWrapper({
	apiKey: google_api_key
})(MapContainer);
