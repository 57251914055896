import React from 'react';
import Popup from 'reactjs-popup';

import './Filter.css';

import filterIcon from '../../assets/filter.png';
import filterCarrot from '../../assets/εóê.png';
import ic_reset from '../../assets/reset.png';

export default function FilterComponent(props) {
  
  const { categories } = props;
  const [title, setTitle] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [resolved, setResolved] = React.useState('');

  const handleFilter = e => {
    e.preventDefault();
    const currentFilter = {
      title,
      location,
      category,
      status,
      resolved
    };
    props.filterList(currentFilter);
  };

  const handleReset = () => {
    setTitle('');
    setCategory('');
    setLocation('');
    setStatus('');
    setResolved('');
    props.resetFilter();
  };

  return (
    <Popup
      position={'bottom right'}
      trigger={
        <button className={'filter-button'}>
          <img alt={'filter-icon'} className={'filter-icon'} src={filterIcon} />
          <span className={'filter-text'}>Filter</span>
          <img
            alt={'filter-carrot'}
            className={'filter-carrot'}
            src={filterCarrot}
          />
        </button>
      }
    >
      <form
        className={'filter-form'}
        onSubmit={handleFilter}
        onReset={handleReset}
      >
        <div className={'filter-form-input-group'}>
          <label className={'filter-form-label'}>Title</label>
          <input
            className={'filter-form-control'}
            type={'text'}
            name={'title'}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <label className={'filter-form-label'}>Location</label>
          <input
            className={'filter-form-control'}
            type={'text'}
            name={'location'}
            value={location}
            onChange={e => setLocation(e.target.value)}
          />
          {!/events/.test(window.location.pathname) && (
            <>
              <label className={'filter-form-label'}>Category</label>
              <select
                className={'filter-form-control'}
                name={'category'}
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                <option value={''}>select category</option>
                {categories ? (
                  categories.map((category, i) => (
                    <option key={i} value={category.category_name}>
                      {category.category_name}
                    </option>
                  ))
                ) : (
                  <option>Loading Options ...</option>
                )}
              </select>
            </>
          )}
          {/issues/.test(window.location.pathname) && (
            <>
              <label className={'filter-form-label'}>Resolved</label>
              <select
                className={'filter-form-control'}
                name={'resolved'}
                value={resolved}
                onChange={e => setResolved(e.target.value)}
              >
                <option value={''}>select resolved</option>
                <option value={1}>Resolved</option>
                <option value={0}>Not Resolved</option>
              </select>
            </>
          )}
          <label className={'filter-form-label'}>Status</label>
          <select
            className={'filter-form-control'}
            name={'status'}
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            <option value={''}>select status</option>
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </select>
        </div>
        <div className={'filter-form-button-group'}>
          <button type={'reset'} className={'filter-form-button-reset'}>
            <img alt={'reset'} src={ic_reset} />
            Reset
          </button>
          <button type={'submit'} className={'filter-form-button-submit'}>
            Filter
          </button>
        </div>
      </form>
    </Popup>
  );
}
