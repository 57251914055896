import React from 'react';

import AppContext from '../bin/config/AppContext';

import Map from '../Components/Utilities/Maps';

export default function Maps() {
  return (
    <AppContext.Consumer>{context => <Map {...context} />}</AppContext.Consumer>
  );
}
