import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import CarouselCaption from 'react-bootstrap/CarouselCaption';

import './Carousel.css';

import ic_add from '../../assets/add_image@2x.png';
import ic_eye from '../../assets/eye@2x.png';
import ic_noimage from '../../assets/ic_no-image.png';

export default class CarouselComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0,
			direction: null
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	handleSelect = (i, e) => this.setState({ index: i, direction: e });

	render = () => {
		const { index, direction } = this.state;
		const { data, addImages } = this.props;
		return (
			<Carousel
				id={'carousel'}
				activeIndex={index}
				direction={direction}
				onSelect={this.handleSelect}
				slide={true}
				controls={data.images !== undefined && data.images.length > 1}
			>
				{data.images !== undefined && data.images.length > 0 ? (
					// Condition True
					data.images.map((image, i) => {
						return (
							<CarouselItem key={i}>
								<img
									className={'carousel-image'}
									src={image ? image : ic_noimage}
									alt={'carousel-item'}
								/>
								<CarouselCaption>
									{!/events/.test(window.location.pathname) &&
										data.category_name !== '' && (
											<span
												className={'carousel-category'}
												style={{ backgroundColor: data.category_color }}
											>
												{data.category_name}
											</span>
										)}
									<span className={'carousel-views'}>
										<img alt={'eye'} src={ic_eye} />
										{data.view_count}
									</span>
									<span className={'carousel-add-image'} onClick={addImages}>
										<img alt={'add'} src={ic_add} />
									</span>
								</CarouselCaption>
							</CarouselItem>
						);
					})
				) : (
					// Condition False
					<CarouselItem>
						<img
							className={'carousel-image'}
							src={ic_noimage}
							alt={'carousel-item'}
						/>
						<CarouselCaption>
							<span className={'carousel-category'} />
							<span className={'carousel-views'}>
								<img alt={'eye'} src={ic_eye} />
							</span>
							<span className={'carousel-add-image'} onClick={addImages}>
								<img alt={'add'} src={ic_add} />
							</span>
						</CarouselCaption>
					</CarouselItem>
				)}
			</Carousel>
		);
	};
}
