import React from 'react';

import './Details.css';

import DetailsCarousel from '../Utilities/Carousel';
import { ConfirmModal, InfoModal } from '../Utilities/Modals';

import ic_edit from '../../assets/edit@2x.png';
import ic_del from '../../assets/Delete.png';
import ic_del_active from '../../assets/ic_delete_red@2x.png';
import ic_location from '../../assets/map@2x.png';
import ic_profile from '../../assets/ic_profile.png';
import Ratings from '../Utilities/Ratings';
import enabledTick from '../../assets/enabled-tick.png';

export default class DetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        title: null,
        location_name: null,
        user_details: {
          image: null,
          name: null,
          created_at: null,
          status: null
        },
        description: null,
        images: [],
        category_name: null,
        view_count: 0,
        status: false,
        is_resolved: false,
        comments: []
      },
      isConfirmOpen: false,
      isInfoOpen: false,
      editTitle: false,
      editLocation: false,
      editDescription: false
    };
    this.addImages = this.addImages.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdits = this.handleEdits.bind(this);
  }

  edit = {
    title: React.createRef(),
    location_name: React.createRef(),
    description: React.createRef()
  };
  toggleEdit = id => {
    switch (id) {
      case 'title':
        return this.setState({ editTitle: !this.state.editTitle });
      case 'location_name':
        return this.setState({ editLocation: !this.state.editLocation });
      case 'description':
        return this.setState({ editDescription: !this.state.editDescription });
      default:
        break;
    }
  };

  handleEdits = event => {
    const { id } = event.target;
    const { data } = this.state;
    const cat = this.props.match.path.replace('/', '').replace('/:id', '');
    if (
      this.edit[id] === undefined ||
      this.edit[id].current === undefined ||
      this.edit[id].current.value === undefined ||
      this.edit[id].current.value === null ||
      this.edit[id].current.value === ''
    )
      return console.error(
        `Invalid ${id !== 'location_name' ? id : 'location'}`
      );
    data[id] = this.edit[id].current.value;
    this.props.updateDetails({ cat, data }, (err, response) =>
      !err && response !== null
        ? this.setState({ response }, this.toggleEdit(id))
        : console.error(err)
    );
  };
  handleToggle = () => {
    const { data } = this.state;
    const cat = this.props.match.path.replace('/', '').replace('/:id', '');
    data.status = !this.state.data.status;
    this.props.updateDetails({ data, cat }, (err, data) =>
      !err && data !== null
        ? this.setState({ data, isInfoOpen: false })
        : console.warn(err)
    );
  };
  handleResolved = () => {
    const { data } = this.state;
    const cat = this.props.match.path.replace('/', '').replace('/:id', '');
    data.is_resolved = !data.is_resolved;
    this.props.updateDetails({ data, cat }, (err, data) => {
      !err && data !== null
        ? this.setState({ data, isInfoOpen: false })
        : console.warn(err);
    });
  };
  handleDelete = () => {
    const { id } = this.props.match.params;
    const cat = this.props.match.path.replace('/', '').replace('/:id', '');
    this.props.deleteDetails({ id, cat }, (err, response) =>
      !err && response !== null ? this.props.history.goBack : console.warn(err)
    );
  };
  handleDeleteComment = commentId => {
    const { id } = this.props.match.params;
    const cat = this.props.match.path.replace('/', '').replace('/:id', '');
    this.props.deleteComment({ id, commentId, cat }, (err, response) =>
      !err && response !== null
        ? this.setState({
            data: response.details
          })
        : this.setState(
            {
              data: this.state.data
            },
            console.warn(err)
          )
    );
  };

  render = () => {
    const { data, editTitle, editLocation, editDescription } = this.state;
    let { title, location_name, description } = this.edit;
    return (
      <div id={'details'}>
        <div id={'details-header'}>
          <button onClick={this.props.history.goBack}>{'< Back'}</button>
          {!editTitle ? (
            <h1 className={'details-title'}>
              {data.title ? data.title : 'Title'}
              <button
                id={'title'}
                className={'details-edit'}
                onClick={() =>
                  this.setState({ editTitle: !this.state.editTitle })
                }
              >
                <img className={'ic_edit'} alt={'ic_edit'} src={ic_edit} />
                Edit
              </button>
            </h1>
          ) : (
            <h1 className={'details-title'}>
              <input
                type={'text'}
                className={'form-control'}
                defaultValue={data.title}
                ref={title}
                placeholder={'Enter Title'}
                onKeyUp={e =>
                  e.which === 27 && this.setState({ editTitle: false })
                }
                required
              />
              <span
                id={'title'}
                className={'details-edit'}
                onClick={this.handleEdits}
              >
                <img className={'ic_edit'} alt={'ic_edit'} src={ic_edit} />
                Save
              </span>
              <span
                className={'details-edit'}
                onClick={() => this.setState({ editTitle: false })}
              >
                Cancel
              </span>
            </h1>
          )}
          {!editLocation ? (
            <h2 className={'details-location'}>
              <img alt={'ic_location'} src={ic_location} />
              {data.location_name ? data.location_name : 'Location'}
              <span
                id={'location_name'}
                className={'details-edit'}
                onClick={event => this.toggleEdit(event.target.id)}
              >
                <img className={'ic_edit'} alt={'ic_edit'} src={ic_edit} />
                Edit
              </span>
            </h2>
          ) : (
            <h2 className={'details-location'}>
              <img alt={'ic_location'} src={ic_location} />
              <input
                type={'text'}
                className={'form-control'}
                defaultValue={data.location_name}
                ref={location_name}
                placeholder={'Enter Location ...'}
                onKeyUp={e =>
                  e.which === 27 && this.setState({ editLocation: false })
                }
              />
              <span
                id={'location_name'}
                className={'details-edit'}
                onClick={this.handleEdits}
              >
                <img className={'ic_edit'} alt={'ic_edit'} src={ic_edit} />
                Save
              </span>
              <span
                className={'details-edit'}
                onClick={() => this.setState({ editLocation: false })}
              >
                Cancel
              </span>
            </h2>
          )}
        </div>
        <div id={'details-grid'}>
          <div id={'column-1'}>
            <div className={'details-carousel'}>
              {/* Carousel Component */}
              <DetailsCarousel data={data} addImages={this.addImages} />
              <h2 className={'details-description-heading'}>
                Description
                <span
                  id={'description'}
                  className={'details-edit'}
                  onClick={
                    !editDescription
                      ? event => this.toggleEdit(event.target.id)
                      : this.handleEdits
                  }
                >
                  <img className={'ic_edit'} alt={'ic_edit'} src={ic_edit} />
                  {!editDescription ? 'Edit' : 'Save'}
                </span>
                {editDescription && (
                  <span
                    className={'details-edit'}
                    style={{ marginLeft: '5px' }}
                    onClick={() => this.setState({ editDescription: false })}
                  >
                    Cancel
                  </span>
                )}
              </h2>
              {!editDescription ? (
                <p className={'details-description'}>
                  {data.description ? data.description : 'Description ...'}
                </p>
              ) : (
                <textarea
                  className={'form-control'}
                  rows={5}
                  defaultValue={data.description}
                  ref={description}
                  onKeyUp={e =>
                    e.which === 27 && this.setState({ editDescription: false })
                  }
                />
              )}
              <input
                id='file'
                type='file'
                name='file'
                style={{ display: 'none' }}
                onChange={this.updateImages}
              />
            </div>
          </div>
          <div id={'column-2'}>
            <div className={'details-author'}>
              {/* Author Component */}
              <AuthorComponent
                author={
                  data.user_details !== undefined ? data.user_details : {}
                }
                status={data.status}
                resolved={data.is_resolved}
                handleResolved={this.handleResolved}
                handleToggle={() =>
                  this.setState({
                    isInfoOpen: true,
                    message: `Make ${data.status ? 'inactive' : 'active'}?`
                  })
                }
                handleDelete={() => this.setState({ isConfirmOpen: true })}
              />
              <InfoModal
                show={this.state.isInfoOpen}
                onHide={() => this.setState({ isInfoOpen: false, message: '' })}
                message={this.state.message}
                handleToggle={this.handleToggle}
              />
              <ConfirmModal
                show={this.state.isConfirmOpen}
                onHide={() => this.setState({ isConfirmOpen: false })}
                handleDelete={this.handleDelete}
              />
            </div>
            <div className={'details-reviews'}>
              {/* Comments Component */}
              <ReviewsComponent
                reviews={data.comments !== undefined ? data.comments : []}
                handleDeleteComment={this.handleDeleteComment}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  componentDidMount = () => {
    this.fileUpload = document.getElementById('file');
    this.formData = new FormData();
    const id = this.props.match.params.id;
    const current_view = this.props.match.path
      .replace('/', '')
      .replace('/:id', '');
    this.props.handleDetails({ id, current_view }, (err, data) =>
      !err && data !== null
        ? this.setState({ data })
        : this.setState({ data: this.state.data }, console.error(err))
    );
  };

  addImages = () => this.fileUpload.click();
  updateImages = () => {
    this.formData.append('post_image', this.fileUpload.files[0]);
    this.props.handleAddImages(
      this.formData,
      this.state.data._id,
      (err, data) =>
        !err && data !== null
          ? this.setState({ data })
          : this.setState({ data: this.state.data }, console.error(err))
    );
    // Reset this.formData after success response from server.
    this.formData = new FormData();
  };

  componentWillUnmount = () =>
    this.setState({
      data: {
        status: null,
        title: null,
        location_name: null,
        user_details: {
          image: null,
          name: null,
          created_at: null,
          status: null
        },
        description: null,
        images: [],
        category_name: null,
        view_count: 0,
        comments: []
      },
      isConfirmOpen: false,
      isInfoOpen: false,
      editTitle: false,
      editLocation: false,
      editDescription: false
    });
}

function AuthorComponent(props) {
  return (
    <div
      id={'author'}
      style={{
        height: /issues/.test(window.location.pathname) ? '340px' : '280px'
      }}
    >
      <h2 className={'author-heading'}>Posted By</h2>
      <div id={'author-grid'}>
        <div className={'author-image'}>
          <img
            alt={'ic_profile_female'}
            src={props.author.image ? props.author.image : ic_profile}
          />
        </div>
        <div className={'author-info'}>{props.author.name}</div>
      </div>
      <span className={'author-created-at'}>
        Posted on {props.author.created_at}
      </span>
      <hr />
      {/issues/.test(window.location.pathname) ? (
        <>
          <div>
            <span className={'author-status'}>Resolved:</span>
            <label
              className={props.resolved ? 'statusTrue' : 'statusFalse'}
              onClick={props.handleResolved}
            >
              <img alt={'enabledTick'} src={enabledTick} />
              &nbsp; Resolved
            </label>
          </div>
          <br />
          <div>
            <span className={'author-status'}>Status:</span>
            <label
              onClick={props.handleToggle}
              className={props.status ? 'activeTrue' : 'activeFalse'}
              style={{ padding: '1px 0' }}
            >
              <span className={'left'}>Active</span>
              <span className={'right'}>Inactive</span>
            </label>
          </div>
        </>
      ) : (
        <>
          <span className={'author-status'}>Status:</span>
          <label
            onClick={props.handleToggle}
            className={props.status ? 'activeTrue' : 'activeFalse'}
          >
            <span className={'left'}>Active</span>
            <span className={'right'}>Inactive</span>
          </label>
        </>
      )}
      <hr />
      <button onClick={props.handleDelete} className={'author-delete'}>
        <div className={'action-card'}>
          <img alt={'ic_del'} src={ic_del} />
          <img alt={'ic_del_actv'} src={ic_del_active} className={'img-top'} />
        </div>
        Delete
      </button>
    </div>
  );
}

function ReviewsComponent(props) {
  return (
    <div id={'reviews'}>
      <h2 className={'reviews-heading'}>Reviews</h2>
      <div id={'reviews-grid'}>
        {props.reviews.length !== 0 ? (
          props.reviews.map((review, i) => {
            return (
              <div className={'review'} key={i}>
                <div className={'review-image'}>
                  <img
                    alt={'ic_review'}
                    src={review.user_image ? review.user_image : ic_profile}
                  />
                </div>
                <div className={'review-content'}>
                  <h3 className={'review-name'}>
                    {review.user_name}
                    {review.user_rating && (
                      <span className={'review-rating'}>
                        <Ratings rating={review.user_rating} />
                      </span>
                    )}
                  </h3>
                  <p className={'review-text'}>{review.comment}</p>
                </div>
                <div className={'review-delete'}>
                  <button onClick={() => props.handleDeleteComment(review.id)}>
                    <img alt={'ic_del'} src={ic_del} />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className={'review'}>
            <div className={'review-content'}>
              <p className={'no-review-text'}>No reviews</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
