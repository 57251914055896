/*=============================================
=               Api Component                 =
=============================================*/
/**
 *
 *      Declare API CRUD functions
 *      Create 2 sets of CRUD functions.
 *      1 for Listings & 1 for Details
 *
 */
/*=====     End of Api Component      ======*/

import axios from 'axios';
import { Api, abortController, signal } from './Api';

import ic_road from '../../assets/road-safety@2x.png';
import ic_traffic from '../../assets/traffic-management@2x.png';
import ic_waste from '../../assets/waste-management@2x.png';
import ic_water from '../../assets/water-sanitation@2x.png';
import ic_electricity from '../../assets/electricity@2x.png';
import ic_health from '../../assets/health-nutrition@2x.png';
import ic_education from '../../assets/education@2x.png';
import ic_cultural from '../../assets/cultural-heritage@2x.png';
import ic_child from '../../assets/child-welfare@2x.png';
import ic_bio from '../../assets/biodiversity@2x.png';
import ic_animal from '../../assets/Animal-welfare@2x.png';
import ic_public from '../../assets/nuisance@2x.png';

// Listings API - GET requests
export async function getListings(cat, callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.get(`${Api[cat]}`, { headers, signal });
    return callback(null, response.data.listings);
  } catch (error) {
    console.log('Something went wrong\n', error);
    return callback({ message: 'Something went wrong', error }, null);
  }
}
export async function getMarkers(cat, { upper_right, bottom_left }, callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.get(`${Api[cat]}`, {
      headers,
      signal,
      params: {
        upper_right,
        bottom_left
      }
    });
    return callback(null, response.data.listings);
  } catch (error) {
    console.log('Something wen wrong', error);
    return callback({ message: 'Something went wrong', error });
  }
}

// Listings API - POST requests
export async function postListings(data, cat, callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.post(`${Api[cat]}`, { data, headers, signal });
    return callback(null, response.data);
  } catch (error) {
    console.log('Something went wrong \n', error);
    return callback({ message: 'Something went wrong', error });
  }
}

// Details API - GET Requests
export async function getDetails(id, cat, callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.get(`${Api[cat]}/${id}`, { headers, signal });
    return callback(null, response.data.details);
  } catch (error) {
    console.log('Something went wrong\n');
    return callback({ message: 'Something went wrong', error });
  }
}

// Details API - PUT Requests
export async function putDetails(data, cat, callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.put(`${Api[cat]}/${data._id}`, data, {
      headers,
      signal
    });
    return callback(null, response.data);
  } catch (error) {
    console.warn('Something went wrong \n', error);
    return callback({ message: 'Something went wrong', error });
  }
}
export async function putImages(data, cat, callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  try {
    let response = await axios.put(`${Api[cat]}/${data._id}`, data.files, {
      headers,
      signal
    });
    return callback(null, response.data);
  } catch (error) {
    console.warn('Something went wrong \n', error);
    return callback({ message: 'Something went wrong', error });
  }
}

// Details API - DELETE Requests
export async function delDetails(id, cat, callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.delete(`${Api[cat]}/${id}`, { headers, signal });
    return callback(null, response.data);
  } catch (error) {
    console.warn('Something went wrong\n', error);
    return callback({ message: 'Something went wrong', error });
  }
}

// Details API - DELETE comments
export async function delComment(
  id = { detail: '', comment: '' },
  cat,
  callback
) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.delete(
      `${Api[cat]}/${id.detail}/comments/${id.comment}`,
      {
        headers,
        signal
      }
    );
    return callback(null, response.data);
  } catch (error) {
    console.warn('Something went wrong\n', error);
    return callback({ message: 'Something went wrong\n', error });
  }
}

// Get Dashboard - GET request
export async function getCategories(callback) {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  let response;
  try {
    response = await axios.get(`${Api['dashboard']}`, { headers, signal });
    response.data.categories = response.data.categories.map(category => {
      switch (category.category_name) {
        case 'Road Safety':
          category.img = ic_road;
          break;
        case 'Traffic Management':
          category.img = ic_traffic;
          break;
        case 'Waste Management':
          category.img = ic_waste;
          break;
        case 'Water & Sanitation':
          category.img = ic_water;
          break;
        case 'Health & Nutrition':
          category.img = ic_health;
          break;
        case 'Biodiversity':
          category.img = ic_bio;
          break;
        case 'Animal Welfare':
          category.img = ic_animal;
          break;
        case 'Child Welfare':
          category.img = ic_child;
          break;
        case 'Education':
          category.img = ic_education;
          break;
        case 'Cultural/Heritage':
          category.img = ic_cultural;
          break;
        case 'Electricity':
          category.img = ic_electricity;
          break;
        case 'Public Interest/Nuisance':
          category.img = ic_public;
          break;
        default:
          break;
      }
      return category;
    });
    return callback(null, response.data.categories);
  } catch (error) {
    console.warn('Something went wrong\n', error);
    return callback({ message: 'Something went wrong', error });
  }
}

// Admin Api Add / Register new admin - POST request
export const addAdmin = async (newAdmin, callback) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  try {
    let response = await axios.post(`${Api['admins']}`, newAdmin, {
      headers,
      signal
    });
    return callback(null, response.data);
  } catch (error) {
    console.warn('Something went wrong\n', error);
    return callback({ message: 'Something went wrong', error });
  }
};

export { abortController };
