/*=============================================
=            Authorization Component          =
=============================================*/
/**
 *
 *      Declare Login, Logout & IsLoggedIn
 *      functions.
 *
 */
/*====  End of Authorization Component  =====*/

import axios from 'axios';
import { Api, signal } from './Api';

const { auth } = Api;

// Auth Api Login - POST request
export const authLogin = async (user, callback) => {
  try {
    let response = await axios.post(`${auth}`, user, signal);
    return callback(null, response.data.user);
  } catch (error) {
    console.log('Something went wrong\n', error);
    return callback(error);
  }
};

// Auth Api Logout - GET request
export const authLogout = async callback => {
  const token = JSON.parse(localStorage.getItem('token'));
  const headers = {
    Authorization: `Bearer ${token}`
  };
  try {
    let response = await axios.get(`${auth}/logout`, { headers, signal });
    return callback(null, response.data);
  } catch (error) {
    console.log('Something went wrong \n');
    return callback(error);
  }
};

// Auth Api IsLoggedIn - GET request
export const authLoggedIn = async (token, callback) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  try {
    let response = await axios.get(`${auth}/isloggedin`, { headers, signal });
    return callback(null, response.data.user);
  } catch (error) {
    console.log('Something went wrong\n');
    return callback(error);
  }
};
