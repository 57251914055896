import React from 'react';
import StarRatings from 'react-star-ratings';

import './Ratings.css';

export default function Ratings(props) {
	return (
		<StarRatings
			rating={props.rating}
			starRatedColor={'gold'}
			starSpacing={'2px'}
			numberofStars={5}
			name={'rating'}
		/>
	);
}
