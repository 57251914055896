import React from 'react';
import { NavLink } from 'react-router-dom';
import Popup from 'reactjs-popup';
import AppContext from '../bin/config/AppContext';

import './Navbar.css';

import logo from '../assets/pinit-logo.png';
import ic_notifs from '../assets/Shape@2x.png';
import ic_users from '../assets/profile-female.png';
import ic_menu from '../assets/ic_Menu.png';

export default function NavbarComponent() {
  const wrapper = document.getElementById('wrapper');
  const toggleSidebar = () => {
    return wrapper.classList.toggle('toggled');
  };
  return (
    <AppContext.Consumer>
      {context => (
        <header id={'navbar'}>
          <nav className={'navbar navbar-expand-lg navbar-light'}>
            <NavLink to={''} className={'navbar-brand'}>
              <img src={logo} alt={'Pinit'} />
            </NavLink>
            <button onClick={toggleSidebar} className={'toggler'}>
              <img src={ic_menu} alt={'toggle'} />
            </button>
            <ul className={'nav-right'}>
              <li>
                <img
                  className={'notifications'}
                  alt={'ic_notifs'}
                  src={ic_notifs}
                />
              </li>
              <li className={'user-name'}>
                <Popup
                  trigger={
                    <span>
                      <img
                        className={'user-profile'}
                        alt={'ic_users'}
                        src={ic_users}
                      />
                      {context.user.name}
                    </span>
                  }
                >
                  <button
                    className={'btn btn-sm btn-danger form-control'}
                    onClick={context.handleConfirmLogout}
                  >
                    {context.user.isLoggedIn ? 'Logout' : 'Login'}
                  </button>
                </Popup>
              </li>
            </ul>
          </nav>
        </header>
      )}
    </AppContext.Consumer>
  );
}
