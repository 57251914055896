import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../bin/config/AppContext';

import Listings from '../Components/Dashboard/Listings';

import ic_noimage from '../assets/ic_no-image.png';
import del from '../assets/Delete.png';
import ic_del_actv from '../assets/ic_delete_red@2x.png';

export default function AssetsListings(props) {
	return (
		<AppContext.Consumer>
			{context => {
				const columns = {
					columns1: [
						{
							Header: '',
							accessor: 'images[0]',
							Cell: row => (
								<img
									className={'bitmap'}
									src={row.value || ic_noimage}
									alt={'people_image'}
								/>
							),
							width: 60
						},
						{
							Header: 'Title',
							accessor: 'title',
							Cell: row => (
								<div className={'title'}>
									<Link to={`peoples/${row.original._id}`}>{row.value}</Link>
								</div>
							)
						},
						{
							Header: 'Location',
							accessor: 'location_name'
						},
						{
							Header: 'Contact Details',
							accessor: 'contact_information'
						},
						{
							Header: 'Category',
							accessor: 'category_name'
						},
						{
							Header: 'Status',
							accessor: 'status',
							width: 155,
							Cell: row => (
								<label
									className={row.value ? 'activeTrue' : 'activeFalse'}
									onClick={() =>
										context.handleConfirmInfo(
											row.original._id,
											`Make ${row.value ? 'inactive' : 'active'}?`
										)
									}
								>
									<span className='left'>Active</span>
									<span className='right'>Inactive</span>
								</label>
							)
						},
						{
							Header: 'Action',
							width: 90,
							Cell: row => (
								<div className={'actions'}>
									<button
										className={'btn'}
										onClick={() => context.handleConfirmOpen(row.original._id)}
									>
										<div className={'action-card'}>
											<img alt={'del'} src={del} />
											<img
												alt={'del-active'}
												src={ic_del_actv}
												className={'img-top'}
											/>
										</div>
									</button>
								</div>
							)
						}
					],
					columns2: [
						{
							Header: '',
							accessor: 'images[0]',
							Cell: row => (
								<img
									className={'bitmap'}
									src={row.value || ic_noimage}
									alt={'place_image'}
								/>
							),
							width: 60
						},
						{
							Header: 'Title',
							accessor: 'title',
							Cell: row => (
								<div className={'title'}>
									<Link to={`places/${row.original._id}`}>{row.value}</Link>
								</div>
							)
						},
						{
							Header: 'Location',
							accessor: 'location_name'
						},
						{
							Header: 'Created on',
							accessor: 'created_time',
							Cell: row => (
								<React.Fragment>
									{new Date(row.value)
										.toLocaleDateString('en-IN', {
											day: 'numeric',
											month: 'short',
											year: 'numeric',
											hour: '2-digit',
											minute: '2-digit',
											hour12: true
										})
										.replace(',', '')}
								</React.Fragment>
							)
						},
						{
							Header: 'Category',
							accessor: 'category_name'
						},
						{
							Header: 'Status',
							accessor: 'status',
							width: 155,
							Cell: row => (
								<label
									className={row.value ? 'activeTrue' : 'activeFalse'}
									onClick={() =>
										context.handleConfirmInfo(
											row.original._id,
											`Make ${row.value ? 'inactive' : 'active'}?`
										)
									}
								>
									<span className='left'>Active</span>
									<span className='right'>Inactive</span>
								</label>
							)
						},
						{
							Header: 'Actions',
							width: 90,
							Cell: row => (
								<div className={'actions'}>
									<button
										className={'btn'}
										onClick={() => context.handleConfirmOpen(row.original._id)}
									>
										<div className={'action-card'}>
											<img alt={'del'} src={del} />
											<img
												alt={'del-active'}
												src={ic_del_actv}
												className={'img-top'}
											/>
										</div>
									</button>
								</div>
							)
						}
					]
				};
				return <Listings {...props} {...context} columns={columns} />;
			}}
		</AppContext.Consumer>
	);
}
