import React from 'react';
import AppContext from '../bin/config/AppContext';

import './Dashboard.css';

import ic_dash from '../assets/dashboard-active@3x.png';
import logo from '../assets/Group 3@2x.png';

export default function Dash() {
  return (
    <AppContext.Consumer>
      {context => {
        const { categories } = context;
        if (categories && categories.length > 0) {
          return (
            <>
              <h1 className={'dashboard-heading'}>
                <img
                  className={'dashboard-icon'}
                  alt={'ic_dash'}
                  src={ic_dash}
                />
                Dashboard
              </h1>
              <div className={'cards-grid'}>
                {categories.map((category, i) => {
                  return (
                    <div className='card' key={i}>
                      <h3 className={'card-title'}>
                        {category.category_name}
                        <img
                          className={'ic_cat'}
                          alt={'ic_cat'}
                          src={category.img}
                        />
                      </h3>
                      <hr />
                      <div className={'card-info-grid'}>
                        <div className={'card-info-issues'}>
                          <span>{category.details.issues}</span>
                          <p>{'issues'}</p>
                        </div>
                        <div className={'card-info-resolved'}>
                          <span>{category.details.resolved}</span>
                          <p>{'resolved'}</p>
                        </div>
                        <div className={'card-info-places'}>
                          <span>{category.details.places}</span>
                          <p>{'places'}</p>
                        </div>
                        <div className={'card-info-people'}>
                          <span>{category.details.people}</span>
                          <p>{'people'}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        } else {
          return (
            <div className={'container'} style={{ textAlign: 'center' }}>
              <div className={'loginLogo'}>
                <img alt='pinit-logo' src={logo} />
              </div>
              Loading ...
            </div>
          );
        }
      }}
    </AppContext.Consumer>
  );
}
