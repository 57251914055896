import React from 'react';
import { Route } from 'react-router-dom';
import AppContext from '../bin/config/AppContext';

// Import Components
import Navbar from '../Components/Navbar';
import Dashboard from '../Components/Dashboard';
import Sidebar from '../Components/Dashboard/Sidebar';

// Import Pages Components
import Login from './Login';
import Events from './Events';
import EventDetails from './EventDetails';
import Assets from './Assets';
import AssetDetails from './AssetDetails';
import Issues from './Issues';
import IssueDetails from './IssueDetails';
import Users from './Users';
import Maps from './Maps';

export default function IndexRouter() {
  return (
    <AppContext.Consumer>
      {context =>
        context.user.isLoggedIn ? (
          <>
            <Navbar />
            <div
              id={'wrapper'}
              className={context.isSidebarVisible ? 'toggled' : ''}
            >
              <Sidebar />
              <div id={'main'}>
                <div id={'dashboard'}>
                  <Route exact path={'/'} component={Dashboard} />
                  <Route exact path={'/events'} component={Events} />
                  <Route exact path={'/events/:id'} component={EventDetails} />
                  <Route exact path={'/assets'} component={Assets} />
                  <Route
                    exact
                    path={['/peoples/:id', '/places/:id']}
                    component={AssetDetails}
                  />
                  <Route exact path={'/issues'} component={Issues} />
                  <Route exact path={'/issues/:id'} component={IssueDetails} />
                  <Route exact path={'/users'} component={Users} />
                  <Route exact path={'/maps'} component={Maps} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Login />
        )
      }
    </AppContext.Consumer>
  );
}
