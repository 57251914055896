import React from 'react';
import { NavLink } from 'react-router-dom';
import AppContext from '../../bin/config/AppContext';

import './Sidebar.css';

import ic_dash from '../../assets/ic_dash_active.png';
import ic_dash_active from '../../assets/dashboard-active.png';
import ic_events from '../../assets/ic_events.png';
import ic_events_active from '../../assets/event-active.png';
import ic_issues from '../../assets/issues.png';
import ic_issues_active from '../../assets/issus-active.png';
import ic_assets from '../../assets/assets.png';
import ic_assets_active from '../../assets/assets-active.png';
import ic_users from '../../assets/ic_humidity.png';
import ic_users_active from '../../assets/user-active.png';
import ic_maps from '../../assets/map - material.png';
import ic_maps_active from '../../assets/map-active.png';
// import ic_reports from '../../assets/ic_light.png';
// import ic_reports_active from '../../assets/Reports-active.png';
import ic_logout from '../../assets/ic_settings.png';

export default function SidebarComponent() {
  return (
    <AppContext.Consumer>
      {context => {
        const isActive = (path, match, location) =>
          !!(match || path.test(location.pathname)); // Active class issue fix
        const activeIcon = path => !!path.test(window.location.pathname);
        return (
          <div id={'sidebar'}>
            <hr />
            <ul className={'list-group'}>
              <li className={'list-group-item'}>
                <NavLink exact to={'/'}>
                  <img
                    alt='ic_dash'
                    src={
                      window.location.pathname === '/'
                        ? ic_dash_active
                        : ic_dash
                    }
                  />
                  Dashboard
                </NavLink>
              </li>
              <li className={'list-group-item'}>
                <NavLink
                  strict
                  to={'/events'}
                  isActive={isActive.bind(this, /events/)}
                >
                  <img
                    alt='ic_events'
                    src={activeIcon(/events/) ? ic_events_active : ic_events}
                  />
                  Events
                </NavLink>
              </li>
            </ul>
            <li className={'line-break'}>&nbsp;</li>
            <ul className={'list-group'}>
              <li className={'list-group-item'}>
                <NavLink
                  strict
                  to={'/issues'}
                  isActive={isActive.bind(this, /issues/)}
                >
                  <img
                    alt='ic_issues'
                    src={activeIcon(/issues/) ? ic_issues_active : ic_issues}
                  />
                  Issues
                </NavLink>
              </li>
              <li className={'list-group-item'}>
                <NavLink
                  to={'/assets'}
                  className={
                    /peoples/.test(window.location.pathname) ||
                    /places/.test(window.location.pathname)
                      ? 'active'
                      : ''
                  }
                >
                  <img
                    alt='ic_assets'
                    src={
                      activeIcon(/assets/) ||
                      activeIcon(/peoples/) ||
                      activeIcon(/places/)
                        ? ic_assets_active
                        : ic_assets
                    }
                  />
                  Assets
                </NavLink>
              </li>
              <li className={'list-group-item'}>
                <NavLink to={'/users'} isActive={isActive.bind(this, /users/)}>
                  <img
                    alt='ic_users'
                    src={activeIcon(/users/) ? ic_users_active : ic_users}
                  />
                  Users
                </NavLink>
              </li>
              <li className={'list-group-item'}>
                <NavLink
                  exact
                  to={'/maps'}
                  isActive={isActive.bind(this, /maps/)}
                >
                  <img
                    alt='ic_maps'
                    src={activeIcon(/maps/) ? ic_maps_active : ic_maps}
                  />
                  Maps
                </NavLink>
              </li>
              {/* <li className={'list-group-item'}>
			<NavLink
				exact
				to={'/reports'}
				isActive={isActive.bind(this, '/reports')}
			>
				<img
					alt='ic_reports'
					src={activeIcon('/reports') ? ic_reports_active : ic_reports}
				/>
				Reports
			</NavLink>
		</li> */}
            </ul>
            <li className={'line-break'}>&nbsp;</li>
            <ul className={'list-group'}>
              <li className={'list-group-item'}>
                <button onClick={context.handleConfirmLogout}>
                  <img alt='ic_logout' src={ic_logout} />
                  Logout
                </button>
              </li>
            </ul>
          </div>
        );
      }}
    </AppContext.Consumer>
  );
}
