// Pinit - Dashboard
import React from 'react';
import AppContext from './AppContext';

import {
	abortController,
	putDetails,
	getCategories,
	getListings,
	getDetails,
  delDetails,
  delComment,
	getMarkers,
	addAdmin,
	putImages
} from '../api/ApiComponent';
import { authLogin, authLogout, authLoggedIn } from '../api/AuthComponent';
import {
	UnAuthModal,
	ConfirmModal,
	AdminModal,
	InfoModal,
	InfoModal as ConfirmToggle,
	InfoModal as ConfirmResolved,
	ConfirmLogout
} from '../../Components/Utilities/Modals';

export default class AppProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {
				_id: null,
				name: null,
				isLoggedIn: false,
				is_super_admin: false
			},
			isSidebarVisible: false,
			isUnAuthOpen: false,
			isInfoOpen: false,
			isConfirmInfoOpen: false,
			isConfirmOpen: false,
			isAdminOpen: false,
			isLogoutOpen: false,
			categories: [],
			details: {},
			message: '',
			tabKey: '',
			current_view: '',
			rowId: '',
			handleConfirmOpen: (id = '') =>
				this.setState({ isConfirmOpen: true, rowId: id }),
			handleConfirmClose: () =>
				this.setState({ isConfirmOpen: false, rowId: null }),
			handleInfoOpen: (message = '') =>
				this.setState({ isInfoOpen: true, message }),
			handleInfoClose: () => this.setState({ isInfoOpen: false, message: '' }),
			handleResOpen: (id = '', message = '') => this.setState({ isConfirmResOpen: true, rowId: id, message}),
			handleConfirmInfo: (id = '', message = '') =>
				this.setState({ isConfirmInfoOpen: true, rowId: id, message }),
			handleAdminOpen: () => this.setState({ isAdminOpen: true }),
			handleAdminClose: () => this.setState({ isAdminOpen: false }),
			handleCloseModal: () => this.setState({ isUnAuthOpen: false }),
			handleConfirmLogout: () =>
				this.setState({ isLogoutOpen: !this.state.isLogoutOpen }),

			handleUnauthorized: (message = '') =>
				this.setState(
					{
						user: {
							_id: null,
							name: null,
							is_super_admin: false,
							isLoggedIn: false,
							isSidebarVisible: false
						},
						isUnAuthOpen: message !== '',
						message: message !== '' ? message : 'Kindly log in ...'
					},
					localStorage.clear(),
					window.location.pathname !== '/' && window.location.replace('/')
				),
			handleLoggedIn: (e = {}, data = { username: '', password: '' }) => {
				e.preventDefault();
				// Condition check - user is not logged in
				data && !this.state.user.isLoggedIn
					? // Condition true - call login & send user object to auth api then update state.
					  authLogin(data, (err = {}, response = {}) =>
							!err && response !== null
								? this.setState(
										{
											user: {
												_id: response._id,
												name: response.name,
												is_super_admin: response.is_super_admin,
												isLoggedIn: true,
												isSidebarVisible: true
											}
										},
										localStorage.setItem(
											'token',
											JSON.stringify(response.token)
										),
										this.state.handleCategories()
										// window.location.replace('/')
								  )
								: this.state.handleUnauthorized(err.message)
					  )
					: // Condition false - check if token present in local storage and call logout then update state.
					  localStorage.length > 0 &&
					  authLogout((err, response) => {
							!err && response !== null
								? this.setState(
										{
											user: {
												_id: null,
												name: null,
												is_super_admin: false,
												isLoggedIn: false
											},
											categories: [],
											isLogoutOpen: false
										},
										() => localStorage.clear(),
										window.location.replace('/')
								  )
								: this.state.handleUnauthorized(err.message);
					  });
			},
			handleTabs: (tabKey, callback) =>
				this.setState(
					{ tabKey },
					callback({ tabKey, data: this.state[tabKey] })
				),
			handleCategories: () =>
				localStorage.length > 0
					? getCategories((err, categories) => {
							!err && categories !== null && this.setState({ categories });
					  })
					: this.setState({ categories: [] }),
			handleListings: (current_view, callback) =>
				getListings(current_view, (err, data) => {
					if (err) return callback(err);
					// Condition check - current view is assets or users
					else if (current_view === 'assets' || current_view === 'users') {
						// Condition true - set state and return data to callback
						const tabs = Object.keys(data).map(tab => tab.replace('_list', ''));
						const newData = {
							current_view,
							tabKey: tabs[0],
							[tabs[0]]: data[tabs[0] + '_list'],
							[tabs[1]]: data[tabs[1] + '_list']
						};
						return this.setState(newData, callback(null, newData));
					} else {
						// Condition false - set state and return data to callback
						const newData = {
							current_view,
							[current_view]: data
						};
						return this.setState(newData, callback(null, newData));
					}
				}),
			handleDetails: ({ id, current_view }, callback) =>
				getDetails(id, current_view, (err, response) => {
					!err && response !== null
						? this.setState(
								{ current_view, details: response },
								callback(null, response)
						  )
						: this.setState({ current_view, details: {} }, callback(err));
				}),
			updateDetails: ({ data, cat }, callback) =>
				putDetails(data, cat, (err, response) => {
					!err && response !== null
						? this.setState({ details: data }, callback(null, data))
						: this.setState({ details: this.state.details }, callback(err));
				}),
			deleteDetails: ({ id, cat }, callback) =>
				delDetails(id, cat, (err, response) => 
					!err && response !== null
						? this.setState(
								{
									[cat]: this.state[cat].filter(data => data._id !== id),
									isConfirmOpen: false,
									rowId: null
								},
								callback(null, response)
						  )
						: this.setState(
								{
									[cat]: this.state[cat],
									isConfirmOpen: false,
									rowId: null
								},
								callback(err)
						  )
				),
			deleteComment: ({ id, commentId, cat }, callback) =>
				delComment({ detail: id, comment: commentId}, cat, (err, response) => 
					!err && response !== null
                ? this.setState(
                  {
                    [cat]: this.state[cat].filter(data => data._id !== id),
                    isConfirmOpen: false,
                    rowId: null
                  },
                  callback(null, response)
                )
                : this.setState(
                  {
                    [cat]: this.state[cat],
                    isConfirmOpen: false,
                    rowId: null
                  },
                  callback(err)
                )
				),
			handleToggle: (id = this.state.rowId) => {
				const { tabKey, current_view } = this.state;
				// Condition check - current view is assets or users
				current_view === 'assets' || current_view === 'users'
					? // Condition True - call PUT api to current TAB and update state.
					  this.setState({
							[tabKey]: this.state[tabKey].map(data => {
								if (data._id === id) {
									data.status = !data.status;
									putDetails(data, tabKey, (err, response) => {
										!err && response !== null
											? console.info(response.message)
											: console.warn(err);
									});
								}
								return data;
							}),
							isConfirmInfoOpen: false
					  })
					: // Condition False - call PUT api to current VIEW and update state.
					  this.setState({
							[current_view]: this.state[current_view].map(data => {
								if (data._id === id) {
									data.status = !data.status;
									putDetails(data, current_view, (err, response) => {
										!err && response !== null
											? console.info(response.message)
											: console.warn(err);
									});
								}
								return data;
							}),
							isConfirmInfoOpen: false
					  });
			},
			handleResolved: (id = this.state.rowId) => {
				const { tabKey, current_view } = this.state;
				current_view === 'assets' || current_view === 'users'
				? // Condition True - call PUT api to current TAB and update state.
					this.setState({
						[tabKey]: this.state[tabKey].map(data => {
							if(data._id === id) {
								data.is_resolved = !data.is_resolved;
								putDetails(data, tabKey, (err, response) => {
									!err && response !== null
										? this.setState({ isConfirmResOpen: false, message: ''})
										: console.warn(err)
								})
							}
							return data;
						})
					})
				: // Condition False - call PUT api to current VIEW and update state.
					this.setState({
						[current_view]: this.state[current_view].map(data => {
							if(data._id === id) {
								data.is_resolved = !data.is_resolved;
								putDetails(data, current_view, (err, response) => {
									!err && data !== null
										? this.setState({ isConfirmResOpen: false, message: ''})
										: console.warn(err)
								})
							}
							return data;
						})
					})
			},
			handleDelete: (id = this.state.rowId) => {
				const { tabKey, current_view } = this.state;
				// Condition check - current view is assets or users
				current_view === 'assets' || current_view === 'users'
					? // Condition True - call del api to current KEY and update state
					  delDetails(id, tabKey, (err, response) => {
							!err && response !== null
								? this.setState({
										[tabKey]: this.state[tabKey].filter(
											data => data._id !== id
										),
										isConfirmOpen: false,
										rowId: null
								  })
								: this.setState(
										{
											[tabKey]: this.state[tabKey],
											isConfirmOpen: false,
											rowId: null
										},
										console.warn(err)
								  );
					  })
					: // Condition False - call del api to current VIEW and update state
					  delDetails(id, current_view, (err, response) => {
							!err && response !== null
								? this.setState({
										[current_view]: this.state[current_view].filter(
											data => data._id !== id
										),
										isConfirmOpen: false,
										rowId: null
								  })
								: this.setState(
										{
											[current_view]: this.state[current_view],
											isConfirmOpen: false,
											rowId: null
										},
										console.warn(err)
								  );
					  });
			},
			handleMarkers: (bounds, callback) =>
				getMarkers('maps', bounds, (err, data) => {
					if (err) callback(err);
					return callback(null, data);
				}),
			handleAddAdmin: newAdmin =>
				addAdmin(newAdmin, (err, response) => {
					!err && response !== null
						? this.setState({
								admins: [...this.state['admins'], response.admin_data],
								isAdminOpen: false
						  })
						: this.setState(
								{
									admins: this.state['admins'],
									isAdminOpen: false
								},
								console.warn(err)
						  );
				}),
			handleAddImages: (files, _id, callback) =>
				putImages({ files, _id }, this.state.current_view, (err, response) => {
					!err && response !== null
						? this.state.handleDetails(
								{ id: _id, current_view: this.state.current_view },
								data => callback(null, data)
						  )
						: callback(err);
				})
		};
		this.state.handleConfirmOpen = this.state.handleConfirmOpen.bind(this);
		this.state.handleConfirmClose = this.state.handleConfirmClose.bind(this);
		this.state.handleConfirmInfo = this.state.handleConfirmInfo.bind(this);
		this.state.handleInfoOpen = this.state.handleInfoOpen.bind(this);
		this.state.handleInfoClose = this.state.handleInfoClose.bind(this);
		this.state.handleAdminOpen = this.state.handleAdminOpen.bind(this);
		this.state.handleAdminClose = this.state.handleAdminClose.bind(this);
		this.state.handleCloseModal = this.state.handleCloseModal.bind(this);
		this.state.handleUnauthorized = this.state.handleUnauthorized.bind(this);
		this.state.handleLoggedIn = this.state.handleLoggedIn.bind(this);
		this.state.handleCategories = this.state.handleCategories.bind(this);
		this.state.handleListings = this.state.handleListings.bind(this);
		this.state.handleTabs = this.state.handleTabs.bind(this);
		this.state.handleDetails = this.state.handleDetails.bind(this);
		this.state.updateDetails = this.state.updateDetails.bind(this);
		this.state.handleToggle = this.state.handleToggle.bind(this);
		this.state.handleDelete = this.state.handleDelete.bind(this);
		this.state.handleMarkers = this.state.handleMarkers.bind(this);
		this.state.handleAddAdmin = this.state.handleAddAdmin.bind(this);
		this.state.handleAddImages = this.state.handleAddImages.bind(this);
	}

	render = () => (
		<AppContext.Provider
			value={{
				...this.state
			}}
		>
			{this.props.children}
			<UnAuthModal
				show={this.state.isUnAuthOpen}
				onHide={this.state.handleCloseModal}
				message={this.state.message}
			/>
			<ConfirmModal
				show={this.state.isConfirmOpen}
				onHide={this.state.handleConfirmClose}
				handleDelete={this.state.handleDelete}
				rowId={this.state.rowId}
			/>
			<AdminModal
				show={this.state.isAdminOpen}
				onHide={this.state.handleAdminClose}
				handleAdd={this.state.handleAddAdmin}
			/>
			<ConfirmLogout
				show={this.state.isLogoutOpen}
				onHide={this.state.handleConfirmLogout}
				logout={this.state.handleLoggedIn}
			/>
			<ConfirmToggle
				show={this.state.isConfirmInfoOpen}
				onHide={() =>
					this.setState({
						isConfirmInfoOpen: false,
						message: ''
					})
				}
				message={this.state.message}
				handleToggle={this.state.handleToggle}
			/>
			<ConfirmResolved
				show={this.state.isConfirmResOpen}
				onHide={() => this.setState({
					isConfirmResOpen: false,
					message: ''
				})}
				message={this.state.message}
				handleToggle={this.state.handleResolved}
			/>
			<InfoModal
				show={this.state.isInfoOpen}
				onHide={this.state.handleInfoClose}
				message={this.state.message}
			/>
		</AppContext.Provider>
	);

	componentDidMount = () =>
		localStorage.length > 0
			? authLoggedIn(
					JSON.parse(localStorage.getItem('token')),
					(err, response) => {
						!err && response !== null
							? this.setState(
									{
										user: {
											_id: response._id,
											name: response.name,
											is_super_admin: response.is_super_admin,
											isLoggedIn: true,
											isSidebarVisible: true
										}
									},
									localStorage.setItem(
										'token',
										JSON.stringify(response.token),
										this.state.handleCategories()
									)
							  )
							: this.state.handleUnauthorized(err.message);
					}
			  )
			: this.state.handleUnauthorized();

	componentWillUnmount = () =>
		this.setState(
			{
				user: {
					_id: null,
					name: null,
					isLoggedIn: false,
					is_super_admin: false
				},
				isSidebarVisible: false,
				isUnAuthOpen: false,
				isInfoOpen: false,
				isConfirmOpen: false,
				isAdminOpen: false,
				categories: [],
				details: {},
				message: '',
				tabKey: '',
				current_view: '',
				rowId: ''
			},
			() => abortController.abort()
		);
}
