import React from 'react';
import AppContext from '../bin/config/AppContext';
import Details from '../Components/Dashboard/Details';

export default function IssueDetails(props) {
  return (
    <AppContext.Consumer>
      {context => <Details {...props} {...context} />}
    </AppContext.Consumer>
  );
}
