import React from 'react';
import ReactTable from 'react-table';

import './Listings.css';
import 'react-table/react-table.css';

import Pagination from '../Utilities/Pagination';
import Filter from '../Utilities/Filter';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';

import logo from '../../assets/Group 3@2x.png';
import ic_events from '../../assets/event-active@3x.png';
import ic_issues from '../../assets/issus-active@3x.png';
import ic_assets from '../../assets/assets-active@3x.png';
import ic_users from '../../assets/user-active@3x.png';
// import ic_reports from "../../assets/Reports-active@3x.png";
import ic_add from '../../assets/ic_add@2x.png';

export default class ListingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.filtered = [];
    this.state = {
      isFiltered: false,
      filtered: [],
      data: [],
      tabKey: '',
      current_view: ''
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);
  }

  handleAddAdmin = newAdmin => {
    console.log('Add new admin ...\n', newAdmin);
    // addAdmin(newAdmin).then(r => console.log(r));
    // TODO send newAdmin to post api.
    this.setState({ isAdminOpen: false });
  };

  handleFilter = currentFilter => {
    const { tabKey, current_view } = this.props;
    // Condition check - current view is assets or users
    current_view === 'assets' || current_view === 'users'
      ? // Condition True - Update filtered list based on tab key.
        (this.filtered = this.props[tabKey]
          .map(
            data =>
              (currentFilter.title
                ? data.title
                    .toLowerCase()
                    .includes(currentFilter.title.toLowerCase()) && data
                : data) &&
              (currentFilter.location !== ''
                ? data.location_name
                    .toLowerCase()
                    .includes(currentFilter.location.toLowerCase()) && data
                : data) &&
              (currentFilter.category !== ''
                ? data.category_name
                    .toLowerCase()
                    .includes(currentFilter.category.toLowerCase()) && data
                : data) &&
              (currentFilter.status !== ''
                ? // eslint-disable-next-line
                  data.status == currentFilter.status && data
                : data)
            // Remove false entries from the array;
          )
          .filter(data => data !== false))
      : // Condition False - Update filtered list based on current view
        (this.filtered = this.props[current_view]
          .map(
            data =>
              (currentFilter.title
                ? data.title
                    .toLowerCase()
                    .includes(currentFilter.title.toLowerCase()) && data
                : data) &&
              (currentFilter.location
                ? data.location_name
                    .toLowerCase()
                    .includes(currentFilter.location.toLowerCase()) && data
                : data) &&
              (current_view !== 'events' && currentFilter.category
                ? data.category_name
                    .toLowerCase()
                    .includes(currentFilter.category.toLowerCase()) && data
                : data) &&
              (current_view === 'issues' && currentFilter.resolved
                ? // eslint-disable-next-line
                  data.is_resolved == currentFilter.resolved && data
                : data) &&
              (currentFilter.status
                ? // eslint-disable-next-line
                  data.status == currentFilter.status && data
                : data)
          )
          .filter(data => data !== false));
    this.setState({
      isFiltered: !!this.filtered,
      filtered: this.filtered ? this.filtered : []
    });
  };

  handleResetFilter = () => {
    this.setState({ isFiltered: false, filtered: [] });
  };

  render = () => {
    const { isFiltered, filtered, tabKey, current_view } = this.state;
    const { user, columns, categories } = this.props;
    const { columns1, columns2 } = columns;
    switch (current_view) {
      // Events Listings Config
      case 'events':
        return (
          <>
            <h1 className={'dashboard-heading'}>
              <img
                className={'dashboard-icon'}
                alt={'ic_events'}
                src={ic_events}
              />
              {current_view}
              <Filter
                filterList={this.handleFilter}
                resetFilter={this.handleResetFilter}
                filters={this.state.filtered}
                categories={categories}
              />
            </h1>
            <ReactTable
              className={'pinit-table'}
              PaginationComponent={Pagination}
              NoDataComponent={NoDataComponent}
              data={isFiltered ? filtered : this.props[current_view]}
              columns={columns}
              defaultPageSize={8}
              sortable={false}
              resizable={false}
            />
          </>
        );
      // Issues Listings Config
      case 'issues':
        return (
          <>
            <h1 className={'dashboard-heading'}>
              <img
                className={'dashboard-icon'}
                alt={'ic_issues'}
                src={ic_issues}
              />
              {current_view}
              <Filter
                filterList={this.handleFilter}
                resetFilter={this.handleResetFilter}
                filters={this.state.filtered}
                categories={categories}
              />
            </h1>
            <ReactTable
              className={'pinit-table'}
              PaginationComponent={Pagination}
              NoDataComponent={NoDataComponent}
              data={isFiltered ? filtered : this.props[current_view]}
              columns={columns}
              defaultPageSize={8}
              sortable={false}
              resizable={false}
            />
          </>
        );
      // Assets Listings Config
      case 'assets':
        return (
          <>
            <h1 className={'dashboard-heading'}>
              <img
                className={'dashboard-icon'}
                alt={'ic_assets'}
                src={ic_assets}
              />
              {current_view}
              <Filter
                filterList={this.handleFilter}
                resetFilter={this.handleResetFilter}
                filters={this.state.filtered}
                categories={categories}
              />
            </h1>
            <Tabs
              defaultActiveKey={'peoples'}
              className={'tabs'}
              onSelect={tabKey =>
                this.props.handleTabs(tabKey, data =>
                  this.setState({ ...data })
                )
              }
            >
              <Tab eventKey={'peoples'} title={'People'}>
                <ReactTable
                  className={'pinit-table'}
                  PaginationComponent={Pagination}
                  NoDataComponent={NoDataComponent}
                  data={isFiltered ? filtered : this.props['peoples']}
                  columns={columns1}
                  defaultPageSize={8}
                  sortable={false}
                  resizable={false}
                />
              </Tab>
              <Tab eventKey={'places'} title={'Places'}>
                <ReactTable
                  className={'pinit-table'}
                  PaginationComponent={Pagination}
                  NoDataComponent={NoDataComponent}
                  data={isFiltered ? filtered : this.props['places']}
                  columns={columns2}
                  defaultPageSize={8}
                  sortable={false}
                  resizable={false}
                />
              </Tab>
            </Tabs>
          </>
        );
      // Users Listings Config
      case 'users':
        return (
          <>
            <h1 className={'dashboard-heading'}>
              <img
                className={'dashboard-icon'}
                alt={'ic_users'}
                src={ic_users}
              />
              {current_view}
              {user.is_super_admin && tabKey === 'admins' && (
                <button
                  className={'add-admin-button'}
                  onClick={this.props.handleAdminOpen}
                >
                  <img alt={'add admin'} src={ic_add} />
                  Add Admin
                </button>
              )}
            </h1>
            <Tabs
              defaultActiveKey={'users'}
              className={'tabs'}
              onSelect={tabKey =>
                this.props.handleTabs(tabKey, data => this.setState(data))
              }
            >
              <Tab eventKey={'users'} title={'Users'}>
                <ReactTable
                  className={'pinit-table'}
                  PaginationComponent={Pagination}
                  NoDataComponent={NoDataComponent}
                  data={this.props['users']}
                  columns={columns1}
                  defaultPageSize={8}
                  sortable={false}
                  resizable={false}
                />
              </Tab>
              <Tab eventKey={'admins'} title={'Admins'}>
                <ReactTable
                  className={'pinit-table'}
                  PaginationComponent={Pagination}
                  NoDataComponent={NoDataComponent}
                  data={this.props['admins']}
                  columns={
                    user.is_super_admin
                      ? columns2
                      : columns2.filter(
                          column =>
                            column.Header !== 'Status' &&
                            column.Header !== 'Actions'
                        )
                  }
                  defaultPageSize={8}
                  sortable={false}
                  resizable={false}
                />
              </Tab>
            </Tabs>
          </>
        );
      // Reports Listings Config
      // case "reports":
      //   return (
      //     <>
      //       <h1 className={"dashboard-heading"}>
      //         <img
      //           className={"dashboard-icon"}
      //           alt={"ic_reports"}
      //           src={ic_reports}
      //         />
      //         {current_view}
      //       </h1>
      //     </>
      //   );
      default:
        return (
          <div className={'container'} style={{ textAlign: 'center' }}>
            <div className={'loginLogo'}>
              <img alt='pinit-logo' src={logo} />
            </div>
            Loading ...
          </div>
        );
    }
  };

  componentDidMount = () => {
    const current_view = this.props.location.pathname.replace('/', '');
    this.props.handleListings(current_view, (err, data) => {
      if (err) return console.error(err);
      this.setState({ ...data });
    });
  };

  componentWillUnmount = () => {
    this.setState({
      isFiltered: false,
      filtered: [],
      tabKey: '',
      current_view: ''
    });
  };
}
const NoDataComponent = () => (
  <div className={'rt-noData'}>Sorry! No data available</div>
);
