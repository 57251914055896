import React from 'react';

import logo from '../assets/Group 3@2x.png';

export default function PageNotFound() {
  return (
    <div
      className={'container'}
      style={{ padding: '60px', textAlign: 'center' }}
    >
      <div className={'loginLogo'}>
        <img alt='pinit-logo' src={logo} />
      </div>
      <h1>404</h1>
      <p>Page Not Found!</p>
    </div>
  );
}
