const routes = [
	'/',
	'/events',
	'/events/:id',
	'/assets',
	'/peoples/:id',
	'/places/:id',
	'/issues',
	'/issues/:id',
	'/users',
	'/maps'
];

export default routes;
