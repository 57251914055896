// const api_url = 'http://192.168.5.40:3030/admin/api/v1/';
// const api_url = "http://172.10.10.162:3001/admin/api/v1/";
const api_url = 'http://pinit.kdwk.in/pinit-test/admin/';
export const Api = {
  dashboard: `${api_url}dashboard`,
  events: `${api_url}events`,
  issues: `${api_url}issues`,
  assets: `${api_url}assets`,
  peoples: `${api_url}peoples`,
  places: `${api_url}places`,
  users: `${api_url}users`,
  admins: `${api_url}admins`,
  maps: `${api_url}map`,
  reports: `${api_url}reports`,
  auth: `${api_url}auth`
};
export const google_api_key = 'AIzaSyCq0qoVnvc_odCyMpjTKo7a5BxLgx87uF8';

export const abortController = new AbortController();
export const signal = abortController.signal;
