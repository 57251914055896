import React from 'react';
import { Link } from 'react-router-dom';

import AppContext from '../bin/config/AppContext';
import Listings from '../Components/Dashboard/Listings';

import enabledTick from '../assets/enabled-tick.png';
import ic_noimage from '../assets/ic_no-image.png';
import del from '../assets/Delete.png';
import ic_del_actv from '../assets/ic_delete_red@2x.png';

export default function IssuesListings(props) {
  return (
    <AppContext.Consumer>
      {context => {
        const current_view = props.location.pathname.replace('/', '');
        const columns = [
          {
            Header: '',
            accessor: 'images[0]',
            Cell: row => (
              <img
                className={'bitmap'}
                src={row.value ? row.value : ic_noimage}
                alt={'issue_image'}
              />
            ),
            width: 60
          },
          {
            Header: 'Title',
            accessor: 'title',
            width: 150,
            Cell: row => (
              <div className={'title'}>
                <Link to={`${current_view}/${row.original._id}`}>
                  {row.value}
                </Link>
              </div>
            )
          },
          {
            Header: 'Location',
            accessor: 'location_name'
          },
          {
            Header: 'Category',
            accessor: 'category_name',
            width: 200
          },
          {
            Header: 'Posted on',
            accessor: 'created_time',
            width: 200,
            Cell: row => (
              <React.Fragment>
                {new Date(row.value)
                  .toLocaleDateString('en-IN', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                  })
                  .replace(',', '')}
              </React.Fragment>
            )
          },
          {
            Header: 'Resolved',
            accessor: 'is_resolved',
            width: 150,
            Cell: row => (
              <label
                className={row.value ? 'statusTrue' : 'statusFalse'}
                onClick={() =>
                  context.handleResOpen(
                    row.original._id,
                    `Mark ${row.value ? 'unresolved' : 'resolved'}`
                  )
                }
              >
                <img alt={'enabledTick'} src={enabledTick} />
                &nbsp; Resolved
              </label>
            )
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 160,
            Cell: row => (
              <label
                className={row.value ? 'activeTrue' : 'activeFalse'}
                onClick={() =>
                  context.handleConfirmInfo(
                    row.original._id,
                    `Make ${row.value ? 'inactive' : 'active'}?`
                  )
                }
              >
                <span className='left'>Active</span>
                <span className='right'>Inactive</span>
              </label>
            )
          },
          {
            Header: 'Actions',
            width: 90,
            Cell: row => (
              <div className={'actions'}>
                <button
                  className={'btn'}
                  onClick={() => context.handleConfirmOpen(row.original._id)}
                >
                  <div className={'action-card'}>
                    <img alt={'del'} src={del} />
                    <img
                      alt={'del-active'}
                      src={ic_del_actv}
                      className={'img-top'}
                    />
                  </div>
                </button>
              </div>
            )
          }
        ];
        return <Listings {...props} {...context} columns={columns} />;
      }}
    </AppContext.Consumer>
  );
}
